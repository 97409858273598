import html2canvas from "html2canvas";

function convertAndShareImage(id: string, serviceUrl: string) {
  const imageElement = document.getElementById(id) as HTMLImageElement;

  html2canvas(imageElement)
    .then((canvas) => {
      const imageURL = canvas.toDataURL("image/png");
      const url = serviceUrl + encodeURIComponent(imageURL);
      window.open(url);
    })
    .catch((e) => console.error(e));
}

export function shareOnWhatsapp(id: string, textShare: string) {
  convertAndShareImage(
    id,
    `https://api.whatsapp.com/send?text=${textShare}&image=`
  );
}

export function shareOnTwitter(id: string, textShare: string) {
  convertAndShareImage(
    id,
    `https://twitter.com/intent/tweet?text=${textShare}&media=`
  );
}

export function shareOnTelegram(id: string) {
  convertAndShareImage(id, "https://telegram.me/share/url?url=");
}

export async function shareNavigator(id: string) {
  const content = document.getElementById(id) as HTMLImageElement;
  try {
    const canvas = await html2canvas(content);
    const base64Data = canvas
      .toDataURL("image/png")
      .replace(/^data:image\/(png|jpeg);base64,/, "");
    const blob = base64ToBlob(base64Data, "image/png");
    const file = new File([blob], "share.png", { type: "image/png" });
    await navigator.share({
      files: [file],
    });
  } catch (error) {
    console.log("error", error);
    alert("Error sharing:" + error);
  }
}

export async function downloadImage(id: string) {
  const content = document.getElementById(id) as HTMLImageElement;
  try {
    const canvas = await html2canvas(content);
    const base64Data = canvas
      .toDataURL("image/png")
      .replace(/^data:image\/(png|jpeg);base64,/, "");

    const downloadLink = document.createElement("a");
    downloadLink.href = `data:image/png;base64,${base64Data}`;
    downloadLink.download = "image.png";
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
  } catch (error) {
    console.log("error", error);
    alert("Error downloading image:" + error);
  }
}

function base64ToBlob(base64Data: string, contentType: string) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

import {
  BORDER_SIZE,
  COL_DRAG_QUANTITY,
  DEFAULT_NUM_OF_ROWS,
  DROP_COLUMNS_VALUE,
  OFFSET_START_ROW_Y,
  OFFSET_X_FALL_BLOCKS_MOB,
  PATTERNS,
  RECT_SIZE,
  RECT_SIZE_FALL,
  YELLOWBLOCK_THRESHOLD,
  YELLOW_RECT_INDEX,
} from "./Constants";
import { GetMatrixByPhase } from "./MatrixUtil";

function getRandomRectPattern() {
  const rectPattern = [
    [3, 3],
    [3, 3, 3],
    [4, 4, 4],
    [4, 4],
  ];
  const randomIndex = Math.floor(Math.random() * rectPattern.length);
  return rectPattern[randomIndex];
}

function generateRandomRows(phase, amount = DEFAULT_NUM_OF_ROWS, isMobile) {
  const rows = [];
  const maxColumnIndex = 4;

  for (let row = 0; row < amount; row++) {
    const pattern = getRandomRectPattern();
    const rowIndex = row;
    const columnIndex = Math.floor(
      Math.random() * (maxColumnIndex - pattern.length + 1)
    );

    const rowRectangles = [];
    const quantityColDrag = COL_DRAG_QUANTITY;

    const randomIndex = Math.random() < YELLOWBLOCK_THRESHOLD ? 4 : 5;
    // force a value for quantity square of fall blocks 
    const randomCountColumns = DROP_COLUMNS_VALUE > -1 ? DROP_COLUMNS_VALUE :
      randomIndex === YELLOW_RECT_INDEX
        ? 1
        : quantityColDrag[Math.floor(Math.random() * quantityColDrag.length)];
    const randomPattern = PATTERNS[randomIndex];

    const matrix = GetMatrixByPhase(phase);
    for (let i = 0; i < randomCountColumns; i++) {
      const value = matrix[rowIndex][columnIndex + i];
      let rightSpaceSize = (RECT_SIZE) * (i + 1 + (isMobile ? OFFSET_X_FALL_BLOCKS_MOB : 0));

      rowRectangles.push({
        value,
        rowIndex,
        columnIndex: columnIndex + i,
        color: randomPattern.style.backgroundColor,
        rect_type: randomPattern.rect_type,
        style: {
          ...randomPattern.style,
          width: `${RECT_SIZE_FALL}px`,
          height: `${RECT_SIZE_FALL}px`,
          top: OFFSET_START_ROW_Y,
          position: "absolute",
          right: `${rightSpaceSize}px`,
          // marginBottom: `${ROWS_GAP_PX}px`,
        },
      });
    }

    rows.push(rowRectangles);
  }

  return rows;
}

export default {
  getRandomRectPattern,
  generateRandomRows,
};

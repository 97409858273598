import { BORDER_SIZE, RECT_SIZE } from "./Constants";
export const MATRIX_PATTERNS = [
  // [
  //   [2, 0, 0, 0, 0, 3],
  //   [2, 1, 1, 1, 1, 3],
  //   [0, 2, 0, 0, 3, 0],
  //   [0, 0, 3, 2, 0, 0],
  //   [0, 3, 0, 0, 2, 0],
  //   [3, 6, 6, 1, 1, 2], // Line with hack
  //   [3, 0, 0, 0, 0, 2],
  //   [3, 1, 1, 1, 1, 2],
  //   [0, 3, 0, 0, 2, 0],
  //   [0, 0, 3, 2, 0, 0],
  //   [0, 2, 0, 0, 3, 0],
  //   [2, 1, 1, 1, 1, 3],
  //   [2, 0, 0, 0, 0, 3],
  // ],
  [
    [2, 0, 0, 0, 0, 0, 0, 3],
    [2, 7, 1, 1, 1, 1, 7, 3],
    [2, 0, 0, 0, 0, 0, 0, 3],
    [0, 2, 7, 1, 1, 7, 3, 0],
    [0, 0, 2, 0, 0, 3, 0, 0],
    [0, 0, 0, 3, 3, 0, 0, 0],
    [0, 0, 3, 0, 0, 2, 0, 0],
    [0, 3, 7, 1, 1, 7, 2, 0],
    [3, 0, 0, 0, 0, 0, 0, 2],
    [3, 7, 7, 7, 7, 6, 6, 2],
    [3, 0, 0, 0, 0, 0, 0, 2],
    [3, 7, 7, 7, 1, 1, 1, 2],
    [3, 0, 0, 0, 0, 0, 0, 2],
    [0, 3, 1, 1, 7, 7, 2, 0],
    [0, 0, 3, 0, 0, 2, 0, 0],
    [0, 0, 0, 3, 3, 0, 0, 0],
    [0, 0, 2, 0, 0, 3, 0, 0],
    [0, 2, 7, 1, 1, 7, 3, 0],
    [2, 0, 0, 0, 0, 0, 0, 3],
  ],
  // [
  //   [2, 0, 0, 0, 0, 0, 3],
  //   [2, 1, 1, 1, 1, 1, 3],
  //   [2, 0, 0, 0, 0, 0, 3],
  //   [0, 2, 1, 1, 1, 3, 0],
  //   [0, 0, 2, 0, 3, 0, 0],
  //
  //   [0, 0, 3, 3, 2, 0, 0], //
  //
  //   [0, 3, 0, 0, 0, 2, 0],
  //
  //   [3, 1, 1, 1, 1, 1, 2],
  //   [3, 0, 0, 0, 0, 0, 2],
  //   [3, 1, 1, 1, 1, 1, 2],
  //   [3, 0, 0, 0, 0, 0, 2],
  //   [3, 6, 6, 6, 1, 1, 2], // Line with hack
  //
  //   [0, 3, 0, 0, 0, 2, 0],
  //
  //   [0, 0, 3, 3, 2, 0, 0], //
  //
  //   [0, 0, 2, 0, 3, 0, 0],
  //   [0, 2, 1, 1, 1, 3, 0],
  //   [2, 0, 0, 0, 0, 0, 3],
  //   [2, 1, 1, 1, 1, 1, 3],
  //   [2, 0, 0, 0, 0, 0, 3],
  // ],
  [
    [2, 0, 0, 0, 0, 0, 0, 3],
    [2, 7, 7, 1, 1, 1, 1, 3],
    [2, 0, 0, 0, 0, 0, 0, 3],
    [0, 2, 1, 1, 1, 7, 3, 0],
    [0, 0, 2, 0, 0, 3, 0, 0],
    [0, 0, 0, 3, 3, 0, 0, 0],
    [0, 0, 3, 0, 0, 2, 0, 0],
    [0, 3, 7, 7, 1, 1, 2, 0],
    [3, 0, 0, 0, 0, 0, 0, 2],
    [3, 1, 1, 1, 1, 6, 6, 6], // Line with hack
    [3, 0, 0, 0, 0, 0, 0, 6],
    [3, 7, 7, 1, 1, 1, 6, 6],
    [3, 0, 0, 0, 0, 0, 0, 2],
    [0, 3, 1, 1, 1, 1, 2, 0],
    [0, 0, 3, 0, 0, 2, 0, 0],
    [0, 0, 0, 3, 3, 0, 0, 0],
    [0, 0, 2, 0, 0, 3, 0, 0],
    [0, 2, 7, 7, 1, 1, 3, 0],
    [2, 0, 0, 0, 0, 0, 0, 3],
  ],
  [
    [2, 0, 0, 0, 0, 0, 0, 3],
    [2, 1, 1, 1, 1, 1, 1, 3],
    [2, 0, 0, 0, 0, 0, 0, 3],
    [0, 2, 1, 1, 1, 1, 3, 0],
    [0, 0, 2, 0, 0, 3, 0, 0],
    [0, 0, 0, 3, 3, 0, 0, 0],
    [0, 0, 3, 0, 0, 6, 0, 0],
    [0, 3, 1, 1, 6, 6, 6, 0],
    [3, 0, 0, 0, 0, 0, 0, 6],
    [3, 1, 1, 1, 6, 6, 6, 6], // Line with hack
    [3, 0, 0, 0, 0, 0, 0, 6],
    [3, 1, 1, 1, 6, 6, 6, 6],
    [3, 0, 0, 0, 0, 0, 0, 6],
    [0, 3, 1, 1, 6, 6, 6, 0],
    [0, 0, 3, 0, 0, 6, 0, 0],
    [0, 0, 0, 3, 3, 0, 0, 0],
    [0, 0, 2, 0, 0, 3, 0, 0],
    [0, 2, 1, 1, 1, 1, 3, 0],
    [2, 0, 0, 0, 0, 0, 0, 3],
  ],
];
export const GetMatrixByPhase = (phase: 1 | 2 | 3) => {
  return MATRIX_PATTERNS[phase - 1];
  // return MATRIX_PATTERNS[2]; // Always returns the same pattern
};

const numRows = (phase) => GetMatrixByPhase(phase)?.length;
const numCols = (phase) => GetMatrixByPhase(phase)[0].length;

export const HeightBoxByPhaseVal = (phase) =>
  numRows(phase) * RECT_SIZE + 2 * BORDER_SIZE;
export const HeightBoxByPhase = (phase) => HeightBoxByPhaseVal(phase) + "px";
export const WidthBoxByPhase = (phase) =>
  numCols(phase) * RECT_SIZE + 2 * BORDER_SIZE + "px";

import BoxItem from "../BoxItem/BoxItem";
import Timer from "../Timer/Timer";
import Header from "../Header/Header";
import MatrixDNA from "../MatrixDNA/MatrixDNA";
import RectanglesDrag from "../RectanglesDrag/RectanglesDrag";
import Score from "../Score/Score";
import { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import {
  RED_RECT_INDEX,
  DROPPABLE_PATTERN_NUMBER,
  FLAGS,
  PATTERNS,
  getScaleFactor,
  getTopScreenPos,
} from "../../util/Constants";
import { GetMatrixByPhase } from "../../util/MatrixUtil";
import { ScoreContext } from "../../contexts/ScoreContext";

export function GameScreen({ setCurrentScreen, phase, handleNext, isMobile, soundIsOn }: any) {
  const [matrix, setMatrix] = useState([]);
  const [countTotalRectanglesDroppable, setCountTotalRectanglesDroppable] =
    useState(0);
  const [countRedRectsOnPhase, setCountRedRectsOnPhase] = useState(0);
  const { countOcurrences, setStartTime } = useContext(ScoreContext);

  useEffect(() => {
    const matrixByPhase = GetMatrixByPhase(phase);
    setMatrix(matrixByPhase.map((row) => row.map((value) => PATTERNS[value])));
    // const countRectanglesDroppable = ScoreUtil.countOcurrences(
    const countRectanglesDroppable = countOcurrences(
      matrixByPhase,
      DROPPABLE_PATTERN_NUMBER
    );
    // const countRedRectsOnPhase = ScoreUtil.countOcurrences(
    const countRedRectsOnPhase = countOcurrences(
      matrixByPhase,
      RED_RECT_INDEX
    );
    setCountTotalRectanglesDroppable(
      countRectanglesDroppable + countRedRectsOnPhase
    );
    setCountRedRectsOnPhase(countRedRectsOnPhase);
    // if (phase == 1) ScoreUtil.saveStartTime();
    if (phase === 1) setStartTime(new Date().getTime().toString());
  }, []);

  return (
    <>
      <Header />
      <div className={styles.contentGameScreen + " contentGame"} style={{ transform: `scale(${getScaleFactor(false)})`, top: `${getTopScreenPos()}px` }}>
        <MatrixDNA phase={phase} matrix={matrix} />
        <RectanglesDrag
          phase={phase}
          countTotalRectanglesDroppable={countTotalRectanglesDroppable}
          matrix={matrix}
          setMatrix={setMatrix}
          handleNext={handleNext}
          countRedRectsOnPhase={countRedRectsOnPhase}
          isMobile={isMobile}
          soundIsOn={soundIsOn}
        />
        {
          <BoxItem
            style={{
              textAlign: FLAGS.enable_score ? "left" : "center",
              display: FLAGS.enable_score ? "flex" : "block",
              position: "relative",
            }}
            className={styles.customBox}
          >
            <Timer setCurrentScreen={setCurrentScreen} phase={phase} />
            <Score />
          </BoxItem>
        }
        <BoxItem
          style={{ textTransform: "uppercase" }}
          className={styles.boxLevel + " boxLevel"}
        >
          {"Fase " + phase}
        </BoxItem>
      </div>
    </>
  );
}

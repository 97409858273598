import styles from "./styles.module.css";
import logo from "../../assets/logo.png";
import hackDoBemSingle from "../../assets/hackDoBemSingle.png";
import { ReactNode, useState } from "react";
import { SCALE_FACTOR, getScaleFactor } from "../../util/Constants";
import styled from "styled-components";
import sonOff from "../../assets/son_off.png";
import sonOn from "../../assets/son_on.png";

const gamemusic = require("../../assets/audio/gamemusic.mp3");
const glitch = require("../../assets/audio/glitch.mp3");

export function InfoContainer({
  children,
  button,
  customStyle,
  isMobile,
  isRelative,
  padding,
  minHeight,
  hideLogo,
  soundIsOn,
  setSoundIsOn,
  setAudioMusic,
  hideSound
}: any) {
  const srcSound: any = soundIsOn ? sonOn : sonOff;
  const toggleAudio = () => {
    if (soundIsOn) {
      setAudioMusic(null);
      setSoundIsOn(false)
    }
    else {
      setSoundIsOn(true)
      setAudioMusic([gamemusic, glitch]);
    }
  }
  return (
    <div className="start-container"
      style={{ transform: `scale(${getScaleFactor(false)})` }}
    >
      {!hideLogo && <>
        <img src={logo} className={styles.logo + " logoIntro1"} draggable="false" alt="logo" />
        <img src={hackDoBemSingle} className={styles.hack + " logoIntro2"} draggable="false" alt="logo" />
      </>}
      <div
        className={(customStyle || styles.container) + " containerResponsive"}
        style={{ padding: padding || '50px 30px', minHeight: minHeight }}
      >
        {children}
        <ContentButton>{button}</ContentButton>
      </div>
      {!hideSound && <SoundBtn className="soundBtn" src={srcSound} onClick={toggleAudio}></SoundBtn>}
    </div>
  );
}

const SoundBtn = styled.img`
position: absolute;
bottom: 20px;
left: 50%;
transform: translateX(-50%);
width: 35px;
z-index: 1000;
`
const ContentButton = styled.div`
  position:absolute;
  bottom:80px;
  left:50%;
  width:100%;
  transform:translateX(-50%);
`
import {
  RED_RECT_INDEX,
  DROPPABLE_PATTERN_NUMBER,
  PATTERNS,
  // DROPPABLE_PATTERN_NUMBER,
  RECT_SIZE,
  INSIDE_RECT_SIZE,
} from "../../util/Constants";
import "./MatrixDNA.css";
import { HeightBoxByPhase, WidthBoxByPhase } from "../../util/MatrixUtil";

function MatrixDNA({ phase, matrix }) {
  const MATRIX_BACKGROUND = PATTERNS[DROPPABLE_PATTERN_NUMBER];
  const MATRIX_BACKGROUND_RED = PATTERNS[RED_RECT_INDEX];
  const OUTSIDE = PATTERNS[0];

  return (
    <div
      className={`${"holder box-dna"} ${"box-phase-" + phase}`}
      style={{
        height: HeightBoxByPhase(phase),
      }}
    >
      <div className="container" style={{ width: WidthBoxByPhase(phase) }}>
        {matrix.map((row, rowIndex) => (
          <div className="row" key={rowIndex}>
            {row.map((rectangle, columnIndex) => {
              if (!rectangle) return

              const isBackground = rectangle.identifier === MATRIX_BACKGROUND.identifier || rectangle.identifier === MATRIX_BACKGROUND_RED.identifier
              const style =
                rectangle.identifier === "outside not droppable"
                  ? OUTSIDE.style
                  : rectangle.identifier === MATRIX_BACKGROUND_RED.identifier
                    ? MATRIX_BACKGROUND_RED.style
                    : MATRIX_BACKGROUND.style;
              return (
                <div
                  className={`rectangle${rectangle.draggable ? " draggable" : ""} ${rectangle.rect_type} ${isBackground ? ' background-rect' : ''}`}
                  style={{
                    ...style,
                    transform: `translate(${columnIndex * RECT_SIZE}px, ${rowIndex * RECT_SIZE
                      }px)`,
                    width: `${RECT_SIZE}px`,
                    height: `${RECT_SIZE}px`,
                  }}
                  key={columnIndex}
                  draggable={rectangle.draggable}
                >
                  {!(
                    rectangle.identifier === MATRIX_BACKGROUND_RED.identifier ||
                    rectangle.identifier === MATRIX_BACKGROUND.identifier
                  ) && (
                      <div
                        className={`rectangle${rectangle.draggable ? " draggable" : ""
                          } ${rectangle.rect_type}`}
                        style={{
                          ...rectangle.style,
                          backgroundColor: rectangle.style.backgroundColor,
                          borderLeft: rectangle.style.borderLeft || "",
                          borderRight: rectangle.style.borderRight || "",
                          borderTop: rectangle.style.borderTop || "",
                          borderBottom: rectangle.style.borderBottom || "",
                          boxShadow: rectangle.style.boxShadow || "",
                          width: `${INSIDE_RECT_SIZE}px`,
                          height: `${INSIDE_RECT_SIZE}px`,
                        }}
                        key={columnIndex}
                        draggable={rectangle.draggable}
                      ></div>
                    )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MatrixDNA;

import * as THREE from 'three';

const GlitchShader: THREE.Shader = {
  uniforms: {
    time: { value: 0 },
    resolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
  },
  vertexShader: `
    void main() {
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform float time;
    uniform vec2 resolution;

    void main() {
      vec2 uv = gl_FragCoord.xy / resolution.xy;
      vec3 color = vec3(0.0);

      // Colored bars
      float barHeight = 0.1;
      float barOffset = 0.5;
      float bar = floor(uv.y / barHeight);
      float barNoise = fract(sin(bar * 43758.5453) * 43758.5453);
      float barIntensity = step(barOffset, barNoise);
      vec3 barColor = mix(vec3(0.3, 0.6, 0.8), vec3(1.0, 0.3, 0.1), barNoise);
      color = mix(color, barColor, barIntensity);

      // Glitch effect
      float noise = fract(sin(dot(uv, vec2(time * 0.1, time * 0.05))) * 43758.5453);
      float glitch = smoothstep(0.5, 0.6, noise); // Adjust glitch intensity threshold
      color += glitch;

      // Make glitch points more transparent
      //float alpha = 1.0 - glitch; // Invert glitch value for transparency
      gl_FragColor = vec4(color, 0.6);
    }
  `,
};

export default GlitchShader;

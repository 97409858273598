import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import logo from "../../assets/logo.png";
import logoPlay from "../../assets/logoplay.png";
import hackDoBem from "../../assets/hackDoBem.png";
import bgBlink from "../../assets/bgBlink.png";
import { DELAY_BLINK, DELAY_GLITCH } from "../../util/Constants";
import { ScoreContext } from "../../contexts/ScoreContext";
import styled from "styled-components";

export function StartScreen({ handleNext }) {
  const [showLogoPlay, setShowLogoPlay] = useState(true);
  const [showBlink, setShowBlink] = useState(false);
  const { setScore } = useContext(ScoreContext);

  useEffect(() => {
    setScore(0);
    // ScoreUtil.setScorePoint(0);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowBlink(true);
      setTimeout(() => {
        setShowBlink(false);
        setShowLogoPlay((prev) => !prev);
      }, DELAY_BLINK);
    }, DELAY_GLITCH * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {showBlink && (
        <img className={styles.fastBlink} src={bgBlink} draggable="false" />
      )}
      <MainContainer className="start-container">
        <img src={logo} className={styles.logo} draggable="false" alt="logo" />
        <button className={styles.startButton} onClick={handleNext}>
          {showLogoPlay ? (
            <img
              className={styles.logoPlay}
              src={logoPlay}
              draggable="false"
              alt="play button"
            />
          ) : (
            <img
              className={styles.hackDoBem}
              src={hackDoBem}
              draggable="false"
            />
          )}
          <div className={styles.playLabel}>PLAY</div>
        </button>
      </MainContainer>
    </>
  );
}
const MainContainer = styled.div`
  top: 50%;
  transform: translateY(-50%);
`

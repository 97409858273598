import { useContext, useEffect, useState } from "react";
import { FLAGS } from "../../util/Constants";
import styles from "./styles.module.css";
import { ScoreContext } from "../../contexts/ScoreContext";

function Score() {
  const [scorePoints, setSetscorePoints] = useState(0);
  const { score, setScore } = useContext(ScoreContext);
  useEffect(() => {
    if (FLAGS.reset_score_game) setScore(0);
    // if (FLAGS.reset_score_game) ScoreUtil.setScorePoint(0);

    setSetscorePoints(score)
    // setSetscorePoints(ScoreUtil.getScorePoint());
  }, []);
  return (
    <div className={styles.container}>
      <div
        className={styles.points}
        id="points"
        style={{ display: FLAGS.enable_score ? "block" : "none" }}
      >
        {scorePoints}
      </div>
    </div>
  );
}

export default Score;

import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { FLAGS, TIME_PHASES } from "../../util/Constants";

function Timer({ setCurrentScreen, phase }) {
  const [countdown, setCountdown] = useState(TIME_PHASES[phase - 1]); // Set the initial countdown value in seconds
  const intervalRef = useRef(null); // Reference to the interval ID

  useEffect(() => {
    // Update the countdown every second
    intervalRef.current = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clean up the interval when the component is unmounted or countdown reaches zero
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  // Clear the interval if countdown reaches zero
  useEffect(() => {
    if (countdown === 0) {
      clearInterval(intervalRef.current);
      if (FLAGS.enable_game_over) {
        setCurrentScreen("gameOver");
      }
    }
  }, [countdown]);

  // Format the countdown time as MM:SS
  const formattedCountdown = `${Math.floor(countdown / 60)
    .toString()
    .padStart(2, "0")}:${(countdown % 60).toString().padStart(2, "0")}`;

  return (
    <div id="timer" className={styles.container}>
      {formattedCountdown}
    </div>
  );
}

export default Timer;

import React, { useEffect, useRef } from "react";
import { FLAGS } from "../../util/Constants";

const AudioPlayer = ({ audioPath: audio, playInLoop, soundIsOn }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (audio && audioRef && audioRef.current && FLAGS.enable_audio && soundIsOn) {
      audioRef.current.src = audio;

      var playPromise = audioRef.current.play();

      if (playPromise !== undefined) {
        playPromise.then(_ => { })
          .catch(error => {
            console.error("error play audio", error);
          });
      }
    } else {
      audioRef.current.src = null
    }
  }, [audio]);

  {
    /* eslint-disable jsx-a11y/media-has-caption */
  }
  return <audio ref={audioRef} loop={playInLoop} />;
};

export default AudioPlayer;

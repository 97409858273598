import React, { useState, useRef, useEffect, useContext } from "react";
import {
  FLAGS,
  OFFSET_START_ROW_Y,
  PATTERNS,
  RECT_SIZE,
  RECT_TYPE,
  ROWS_GAP_PX,
  TOP_OFFSET_FALL_SPEED,
  WHITE_RECT_INDEX,
  YELLOW_RECT_INDEX,
  getScaleFactor,
  getScaleFactorDropX,
  getScaleFactorDropY,
} from "../../util/Constants";
import GeneratorUtil from "../../util/GeneratorUtil";
import styles from "./styles.module.css";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import notification1 from "../../assets/audio/notification1.mp3";
import error1 from "../../assets/audio/error1.mp3";
import success from "../../assets/audio/sucess.mp3";
import bigReverb from "../../assets/audio/big-reverb.mp3";
import bubble from "../../assets/audio/bubble.mp3";
import { ScoreContext } from "../../contexts/ScoreContext";

const scaleFactor = getScaleFactor(true);
const scaleFactorDropX = getScaleFactorDropX(true);
const scaleFactorDropY = getScaleFactorDropY(true);

function RectanglesDrag({
  phase,
  setMatrix,
  countTotalRectanglesDroppable,
  matrix,
  handleNext,
  countRedRectsOnPhase,
  isMobile,
  soundIsOn
}) {
  const [draggingBoxRow, setDraggingBoxRow] = useState([]);
  const [fallBoxRow, setFallBoxRow] = useState([]);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);
  const containerRef = useRef(null);
  const dragRef = useRef({ offsetX: 0, offsetY: 0 });
  const [audio, setAudio] = useState(null);
  const [countFilledRects, setCountFilledRects] = useState(0);
  const [dragPrevPosition, setDragPrevPosition] = useState([]);
  const [fallElementInterval, setFallElementInterval] = useState();
  const [animatedBoxRow, setAnimatedBoxRow] = useState([]);
  const [blockIsMoving, setBlockIsMoving] = useState(false);
  const { addPoints } = useContext(ScoreContext);

  useEffect(() => {
    setFallBoxRow(GeneratorUtil.generateRandomRows(phase, 1, isMobile));
  }, []);

  useEffect(() => {
    document.addEventListener("mouseup", handleDragEnd);
    document.addEventListener("touchend", handleDragEnd);
    return () => {
      document.removeEventListener("mouseup", handleDragEnd);
      document.removeEventListener("touchend", handleDragEnd);
    };
  }, [draggedRowIndex, draggingBoxRow]);

  useEffect(() => {
    if (draggingBoxRow.length > 0) {
      document.addEventListener("mousemove", handleDragMove);
      document.addEventListener("touchmove", handleDragMove);
    } else {
      document.removeEventListener("mousemove", handleDragMove);
      document.removeEventListener("touchmove", handleDragMove);
    }

    return () => {
      document.removeEventListener("mousemove", handleDragMove);
      document.removeEventListener("touchmove", handleDragMove);
    };
  }, [draggingBoxRow]);

  useEffect(() => {
    const createElementInterval = setInterval(() => {
      setFallBoxRow((prevRows) => {
        const newRows = [...prevRows];
        newRows.push(createNewRow(prevRows.length));

        return newRows;
      });
    }, 1500);

    setFallElementInterval(createElementInterval);

    return () => {
      clearInterval(createElementInterval);
    };
  }, []);

  useEffect(() => {
    const fallInterval = setInterval(() => {
      setFallBoxRow((prevRows) => {
        if (prevRows.length === 0) {
          return GeneratorUtil.generateRandomRows(phase, 1, isMobile);
        }

        let newRows = [...prevRows];
        newRows = newRows.map((row, rowIndex) => {
          const newRowRects = row?.map((rect) => {
            const newRect = { ...rect };
            if (
              newRect.style &&
              newRect.style.top !== undefined &&
              !newRect.isDragged
            ) {
              let nextTop = newRect.style.top;

              nextTop += TOP_OFFSET_FALL_SPEED;
              setTransitionStyle(newRect, true);

              const containerHeight = containerRef.current.clientHeight + 50;

              if (
                nextTop > containerHeight &&
                newRect.style.display !== "none"
              ) {
                newRect.style = {
                  ...newRect.style,
                  top: nextTop,
                  display: "none",
                };
              } else {
                newRect.style = { ...newRect.style, top: nextTop };
              }
            }
            return newRect;
          });
          return newRowRects;
        });

        return newRows;
      });
    }, 250);

    return () => {
      clearInterval(fallInterval);
    };
  }, []);

  const handleFallingRectDragStart = (rowIndex, offsetX, offsetY) => {
    if (fallBoxRow.length < rowIndex + 1) return;

    setAudio(null);

    const selectedRow = fallBoxRow[rowIndex];
    const draggedRow = structuredClone(selectedRow);
    const draggedStyle = draggedRow.map((r) => r.style);

    setDragPrevPosition(draggedStyle);
    setDraggingBoxRow(() =>
      draggedRow?.map((e) => {
        setTransitionStyle(e, false);
        e.style = {
          ...e.style,
          position: "relative",
          left: 0,
        };
        return e;
      })
    );

    fallBoxRow[rowIndex] = selectedRow.map((rect) => {
      const newRect = { ...rect };
      newRect.isDragged = true;
      newRect.style = { ...newRect.style, display: "none" };
      newRect.prevRowIndex = rowIndex;
      return newRect;
    });
  };

  const handleDragEnd = (event) => {
    onDrop(event, draggingBoxRow, draggedRowIndex);
  };

  const handleDragMove = (event) => {
    const ev = event.targetTouches?.[0] || event;
    const clientX = ev.clientX;
    const clientY = ev.clientY;
    const containerRect = containerRef.current.getBoundingClientRect();
    const containerOffsetX = containerRect.left + window.scrollX;
    const containerOffsetY = containerRect.top + window.scrollY;

    const mouseX = (clientX - containerOffsetX) / scaleFactor;
    const mouseY = (clientY - containerOffsetY) / scaleFactor;

    setBlockIsMoving(true);

    if (draggingBoxRow.length > 0) {
      const updatedDraggingRow = draggingBoxRow.map((rectangle) => {
        const left = mouseX - RECT_SIZE / 2 - dragRef.current.offsetX;
        const top = mouseY - RECT_SIZE / 2 - dragRef.current.offsetY;
        const style = { ...rectangle.style, left, top };
        return { ...rectangle, style };
      });

      setDraggingBoxRow(updatedDraggingRow);
    }
  };

  const createNewRow = (newRowIndex) => {
    const [generatedRow] = GeneratorUtil.generateRandomRows(phase, 1, isMobile);
    const newRow = generatedRow.map((rect, rectIndex) => {
      const newRect = { ...rect };
      newRect.rowIndex = newRowIndex;
      newRect.style = {
        ...newRect.style,
        top: -ROWS_GAP_PX + OFFSET_START_ROW_Y,
      };
      setTransitionStyle(newRect, true);
      return newRect;
    });
    return newRow;
  };

  const setTransitionStyle = (newRect, isMove) => {
    newRect.style = {
      ...newRect.style,
      transition: isMove ? "top 500ms linear" : "none",
    };
  };

  const onDrop = (event, droppedRow, draggedRowIndex) => {
    let cursorX, cursorY;

    if (event.type === "touchend") {
      const touch = event.changedTouches[0];
      cursorX = touch.clientX;
      cursorY = touch.clientY;
    } else {
      cursorX = event.clientX;
      cursorY = event.clientY;
    }

    cursorX /= scaleFactorDropX;
    cursorY /= scaleFactorDropY;

    const firstRowRect = document
      .querySelector(".row:first-child")
      ?.getBoundingClientRect();
    const lastRowRect = document
      .querySelector(".row:last-child")
      ?.getBoundingClientRect();

    clearDragRow();

    const isValidByCursor = validate(
      null,
      null,
      null,
      null,
      cursorX,
      cursorY,
      firstRowRect,
      lastRowRect
    );

    if (!isValidByCursor) return;

    const targetRowIndex = Math.floor((cursorY - firstRowRect.top) / RECT_SIZE);
    const targetRow = document.querySelectorAll(".row")[targetRowIndex];

    if (targetRow) {
      const firstChild = targetRow.firstElementChild;

      if (firstChild) {
        const firstRect = firstChild.getBoundingClientRect();
        const targetColIndex = Math.floor(
          (cursorX - firstRect.left) / RECT_SIZE
        );

        const rectFirstDragType = droppedRow?.[0]?.rect_type;
        const matrixRectFirstDestiny = matrix[targetRowIndex]?.[targetColIndex];
        const rectFirstMatrixType = matrixRectFirstDestiny?.rect_type;
        const quantityRectsDrag = droppedRow.length;
        const matrixRectLastDestiny =
          matrix[targetRowIndex]?.[targetColIndex + quantityRectsDrag - 1];
        const rectLastMatrixType = matrixRectLastDestiny?.rect_type;

        const isValidByRect = validate(
          rectFirstDragType,
          rectFirstMatrixType,
          quantityRectsDrag,
          rectLastMatrixType,
          cursorX,
          cursorY,
          null,
          null,
          true
        );

        if (!isValidByRect) return;

        const newMatrix = [...matrix];

        if (droppedRow.length > 0) {
          console.log({ droppedRow });
          for (const [index, rect] of droppedRow.entries()) {
            const targetColumnIndex = targetColIndex + index;

            newMatrix[targetRowIndex][targetColumnIndex] = PATTERNS[rect.rect_type === "yellow" ? YELLOW_RECT_INDEX : WHITE_RECT_INDEX];

          }

          setMatrix(newMatrix);

          const totalFilledRects = countFilledRects + droppedRow.length;
          addPoints(droppedRow.length);
          setCountFilledRects(totalFilledRects);

          finishPhase(totalFilledRects, countTotalRectanglesDroppable);
        }
      }
    }
  };

  const finishPhase = (totalFilledRects, countTotalRectanglesDroppable) => {
    if (totalFilledRects >= countTotalRectanglesDroppable) {
      // setAudio(success);
      setAudio(bigReverb)
      setTimeout(() => {
        handleNext();
      }, 1500);
    }
  }

  const clearDragRow = () => {
    setDraggedRowIndex(null);
    setDraggingBoxRow([]);
  };

  const validate = (
    rectFirstDragType,
    rectFirstMatrixType,
    quantityRectsDrag,
    rectLastMatrixType,
    cursorX,
    cursorY,
    firstRowRect,
    lastRowRect,
    ignoreCursor
  ) => {
    let isValid = true;
    if (FLAGS.allow_drop_rectangles_anywhere) return isValid;

    if (cursorY && !ignoreCursor) {
      const isWithinValidRows =
        cursorY >= firstRowRect?.top &&
        cursorY >= lastRowRect?.bottom &&
        cursorX >= firstRowRect?.left &&
        cursorX >= lastRowRect?.left;

      if (!isWithinValidRows) {
        console.error("invalid rows");
        isValid = false;
      }
    } else {
      // RULES
      if (!rectFirstDragType || !rectFirstMatrixType) {
        console.error("ERROR0: Could not identify drag or drop rect type");
        isValid = false;
      }

      // Allow drop yellow blocks on purple placeholder
      if (
        rectFirstDragType === RECT_TYPE.YELLOW &&
        rectFirstMatrixType !== RECT_TYPE.RED
      ) {
        console.error("ERROR1: Allow drop yellow blocks on purple placeholder");
        isValid = false;
      }

      // // Prevent drop 3 yellows on 2 red or 2 yellows on 3 red
      // if (
      //   rectFirstDragType === RECT_TYPE.YELLOW &&
      //   quantityRectsDrag !== countRedRectsOnPhase
      // ) {
      //   console.error("ERROR2: Dropping more yellows than reds");
      //   isValid = false;
      // }

      // Allow drop white blocks on transparent placeholders
      if (
        rectFirstDragType === RECT_TYPE.WHITE &&
        rectFirstMatrixType !== RECT_TYPE.DROPABBLE
      ) {
        console.error("ERROR3: Allow drop yellow blocks on purple placeholder");
        isValid = false;
      }

      // Prevent drop white on purple and yellow on transparent
      if (
        rectFirstDragType === RECT_TYPE.WHITE &&
        rectFirstMatrixType === RECT_TYPE.RED
      ) {
        console.error(
          "ERROR4: Prevent drop white on purple and yellow on transparent"
        );
        isValid = false;
      }

      // Prevent drop last column on not droppable border
      if (
        rectLastMatrixType === RECT_TYPE.NOT_DROPPABLE ||
        !rectLastMatrixType
      ) {
        console.error(
          "ERROR5: Prevent drop last column on not droppable border"
        );
        isValid = false;
      }

      // Prevent drop last column outside of border
      if (!rectLastMatrixType) {
        console.error("ERROR6: Prevent drop last column outside of border");
        isValid = false;
      }

      // prevent 2 or 3 white with dropped with first or last position dropped on red
      if (
        rectFirstDragType === RECT_TYPE.WHITE &&
        (rectFirstMatrixType === RECT_TYPE.RED || rectLastMatrixType === RECT_TYPE.RED)
      ) {
        console.error("ERROR7: Dropping white block (2 or 3) with first or last square on red placeholder");
        isValid = false;
      }
      // console.log(
      //   'rectFirstDragType', rectFirstDragType,
      //   'rectFirstMatrixType', rectFirstMatrixType,
      //   'rectLastMatrixType', rectLastMatrixType,
      //   'quantityRectsDrag', quantityRectsDrag
      // )
    }

    // Drop yellow on red success
    if (blockIsMoving) {
      if (isValid) {
        if (
          rectFirstDragType === RECT_TYPE.YELLOW &&
          rectFirstMatrixType === RECT_TYPE.RED
        ) {
          isValid = true;
          setAudio(success);
        } else {
          isValid = true;
          setAudio(notification1);
        }
      } else {
        setAudio(error1);
      }
    }

    if (!isValid) {
      fadeDestroyRow(cursorX, cursorY);
    }

    return isValid;
  };

  const fadeDestroyRow = (cursorX, cursorY) => {
    if (draggingBoxRow.length === 0) return;

    const { rowIndex } = draggingBoxRow[0];

    if (!rowIndex) return;

    const DELAY_ANIM = 500;
    const DELAY_FADE = 50;

    const boxRowNew = draggingBoxRow.map((rectangle) => {
      rectangle.isDragged = false;
      rectangle.style = {
        ...rectangle.style,
        opacity: 1,
        transition: 'opacity 0.5s ease',
      };
      return rectangle;
    });

    setDraggingBoxRow([]);
    setAnimatedBoxRow(boxRowNew);

    const smokeElement = document.createElement("div");
    smokeElement.classList.add("smoke-effect");

    const content = document.querySelector("#content-rect");
    document.body.appendChild(smokeElement);

    setAudio(bubble);

    if (boxRowNew.length > 0) {
      let offsetX = boxRowNew.length === 1 ? 50 : boxRowNew.length === 2 ? 30 : 20
      let offsetY = 50;
      if (isMobile) offsetX += 20;
      if (isMobile) offsetY += 20;

      const { left, right, top, width, height } = boxRowNew[0].style;
      smokeElement.style.left = `${cursorX - offsetX}px`;
      smokeElement.style.top = `${cursorY - offsetY}px`;
      smokeElement.style.width = `${width}px`;
      smokeElement.style.height = `${height}px`;
    }

    setTimeout(() => {
      boxRowNew.forEach((rectangle, index) => {
        rectangle.style = {
          ...rectangle.style,
          opacity: 0
        }
      });
    }, DELAY_FADE);

    setTimeout(() => {
      smokeElement.remove();
      setAnimatedBoxRow([]);
    }, DELAY_ANIM);
  };


  return (
    <div
      className={styles.content}
      id="content-rect"
      tabIndex="-1"
      ref={containerRef}
      role="button"
    >
      <div className={styles.rowRect}>
        {draggingBoxRow?.map((rectangle, rowIndex) => {
          return (
            <div
              className={`${styles.colRect} ${styles.dragRectRow}${PATTERNS[rectangle?.value]?.draggable ? "draggable" : ""
                }`}
              style={rectangle?.style}
              key={rowIndex}
            />
          );
        })}
      </div>
      <div className={styles.rowRect}>
        {animatedBoxRow?.map((rectangle, rowIndex) => {
          return (
            <div
              className={`${styles.colRect} ${styles.dragRectRow}${PATTERNS[rectangle?.value]?.draggable ? "draggable" : ""
                }`}
              style={rectangle?.style}
              key={rowIndex}
            />
          );
        })}
      </div>
      {fallBoxRow.map((rowRects, rowIndex) => {
        return (
          rowRects && (
            <div className={styles.rowRect} key={rowIndex}>
              {rowRects.map((rectangle, index) => {
                return (
                  <div
                    className={`${styles.colRect} ${" fall-rect "} ${PATTERNS[rectangle?.value]?.draggable ? "draggable" : ""
                      }`}
                    style={rectangle?.style}
                    key={index}
                    onMouseDown={(e) =>
                      handleFallingRectDragStart(
                        rowIndex,
                        e.nativeEvent.offsetX,
                        e.nativeEvent.offsetY
                      )
                    }
                    onTouchStart={(e) =>
                      handleFallingRectDragStart(
                        rowIndex,
                        e.targetTouches[0].clientX,
                        e.targetTouches[0].clientY
                      )
                    }
                  >
                    {/* {rowIndex} */}
                  </div>
                );
              })}
            </div>
          )
        );
      })}
      <AudioPlayer audioPath={audio} soundIsOn={soundIsOn} />
    </div>
  );
}

export default RectanglesDrag;

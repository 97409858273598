import dna1 from "../../assets/dna1.png";
import dna2 from "../../assets/dna2.png";
import styled from "styled-components";

function BackgroundDNA() {
  return (
    <Content>
      <img src={dna1} className="dna dna1" draggable="false" />
      <img src={dna2} className="dna dna2" draggable="false" />
    </Content>
  );
}

export default BackgroundDNA;

const Content = styled.div`
  .dna {
    width: 500px;
    height: auto;
    position: absolute;
    opacity: 0.45;
    pointer-events: none;
    user-select: none;
  }

  .dna1 {
    left: 0;
    top: 0;
  }
  .dna2 {
    right: 0;
    bottom: 0;
  }
`;

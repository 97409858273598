import styles from "./styles.module.css";

function ButtonGame({ children, onClick, small, style, showArrow, isMargin, width }: any) {
  return (
    <>
      <button
        className={small ? styles.btnSmall : styles.btn}
        onClick={onClick}
        style={{ width: width, margin: isMargin && '0 auto', display: isMargin && 'block' }}
      >
        {children}
        {showArrow && <span style={{ marginLeft: '10px' }}>{">"}</span>}
      </button>
    </>
  );
}

export default ButtonGame;

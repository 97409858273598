import logoCodigosWhite from "../../assets/new/logoCodigosWhite.png";
import hackDoBemSingle from "../../assets/hackDoBemSingle.png";
import styles from "./styles.module.css";

function Header() {
  return (
    <>
      <div className={styles.barCustom + " barCustom"}></div>
      <div className={styles.logos + " logosMain"}>
        <img
          src={logoCodigosWhite}
          className={styles.logo1 + " logo1"}
          draggable="false"
          alt="logo1"
        />
        <img
          src={hackDoBemSingle}
          className={styles.logo2 + " logo2"}
          draggable="false"
          alt="logo2"
        />
      </div>
    </>
  );
}

export default Header;

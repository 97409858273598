import { useEffect, useRef, useState } from "react";
import "./App.css";
import "./responsive.css";
import GlitchBackground from "./components/Background/GlitchBackground";
import LineBackground from "./components/Background/LineBackground";
import { TutorialScreen } from "./components/TutorialScreen/TutorialScreen";
import { StartScreen } from "./components/StartScreen/StartScreen";
import { FLAGS, PHASES } from "./util/Constants";
import BackgroundDNA from "./components/Background/BackgroundDNA";
import { NextPhaseScreen } from "./components/NextPhaseScreen/NextPhaseScreen";
import { GameOver } from "./components/GameOver/GameOver";
import { Congrats } from "./components/Congrats/Congrats";
import { ShareScreen } from "./components/ShareScreen/ShareScreen";
import { GameScreen } from "./components/GameScreen/GameScreen";
import AudioPlayer from "./components/AudioPlayer/AudioPlayer";

import styled from "styled-components";

const gamemusic = require("./assets/audio/gamemusic.mp3");
const glitch = require("./assets/audio/glitch.mp3");

function App() {
  const [currentScreen, setCurrentScreen] = useState<GameScreen>(
    FLAGS.start_on_game ? "game1" : "start"
  );
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [audioMusic, setAudioMusic] = useState([]);
  const isMobile = window.innerWidth < 768;
  const lastTap = useRef(0);
  const [soundIsOn, setSoundIsOn] = useState(true);
  const [indexPhase, setIndexPhase] = useState(0);

  const steps = [
    "start",
    "tutorial",
    "game1",

    "nextPhase1_1",
    "nextPhase1_2",
    "nextPhase1_3",

    "game2",

    "nextPhase2_1",
    "nextPhase2_2",
    "nextPhase2_3",

    "game3",

    "nextPhase3_1",
    "nextPhase3_2",
    "nextPhase3_3",

    // "congrats",
    "share",
    "gameOver",
  ];

  const stepsHideSound = [
    "start", "share", "gameOver"
  ]
  const nextStep = () => {
    if (!currentScreen.includes("nextPhase")) setIsTransitioning(true);
    if (currentScreen === "start") {
      setAudioMusic([gamemusic, glitch]);
    }
    setCurrentScreen((old) => {
      const index = steps.indexOf(old);
      const nextIndex = index + 1;
      if (nextIndex >= steps.length) {
        return steps[0] as GameScreen;
      } else {
        return steps[nextIndex] as GameScreen;
      }
    });
  };

  const prevStep = () => {
    setCurrentScreen((old) => {
      const index = steps.indexOf(old);
      const nextIndex = index - 1;
      if (nextIndex < 0) {
        return steps[0] as GameScreen;
      } else {
        return steps[nextIndex] as GameScreen;
      }
    });
  }

  const handleTap = () => {
    const now = Date.now();
    const DOUBLE_TAP_DELAY = 300;

    if (now - lastTap.current <= DOUBLE_TAP_DELAY) {
      nextStep();
    }
    lastTap.current = now;
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.keyCode === 32 || event.keyCode == 39) && FLAGS.enable_next_hack) {
        nextStep();
      }
      if ((event.keyCode === 37) && FLAGS.enable_next_hack) {
        if (currentScreen == steps[0]) {
          setCurrentScreen(steps[steps.length - 2] as GameScreen);
        } else {
          prevStep();
        }
      }
    };

    const handleTouchStart = (event) => {
      if (FLAGS.enable_next_hack)
        handleTap();
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("touchstart", handleTouchStart);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);
  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  useEffect(() => {
    if (currentScreen == "game1") setIndexPhase(1)
    if (currentScreen == "game2") setIndexPhase(2)
    if (currentScreen == "game3") setIndexPhase(3)
  }, [currentScreen])

  const Screens = {
    start: <StartScreen handleNext={nextStep} />,
    tutorial: <TutorialScreen setSoundIsOn={setSoundIsOn} setAudioMusic={setAudioMusic} soundIsOn={soundIsOn} handleNext={nextStep} isMobile={isMobile} />,
    game1: (
      <GameScreen
        setCurrentScreen={setCurrentScreen}
        handleNext={nextStep}
        phase={PHASES[0]}
        isMobile={isMobile}
        soundIsOn={soundIsOn}
      />
    ),
    nextPhase1_1: <NextPhaseScreen setSoundIsOn={setSoundIsOn} setAudioMusic={setAudioMusic} soundIsOn={soundIsOn} handlePrev={prevStep} handleNext={nextStep} phaseKey={11} isMobile={isMobile} />,
    nextPhase1_2: <NextPhaseScreen setSoundIsOn={setSoundIsOn} setAudioMusic={setAudioMusic} soundIsOn={soundIsOn} handlePrev={prevStep} handleNext={nextStep} phaseKey={12} isMobile={isMobile} />,
    nextPhase1_3: <NextPhaseScreen setSoundIsOn={setSoundIsOn} setAudioMusic={setAudioMusic} soundIsOn={soundIsOn} handlePrev={prevStep} handleNext={nextStep} phaseKey={13} isMobile={isMobile} />,
    game2: (
      <GameScreen
        setCurrentScreen={setCurrentScreen}
        handleNext={nextStep}
        phase={PHASES[1]}
        isMobile={isMobile}
        soundIsOn={soundIsOn}

      />
    ),
    nextPhase2_1: <NextPhaseScreen setSoundIsOn={setSoundIsOn} setAudioMusic={setAudioMusic} soundIsOn={soundIsOn} handlePrev={prevStep} handleNext={nextStep} phaseKey={21} isMobile={isMobile} />,
    nextPhase2_2: <NextPhaseScreen setSoundIsOn={setSoundIsOn} setAudioMusic={setAudioMusic} soundIsOn={soundIsOn} handlePrev={prevStep} handleNext={nextStep} phaseKey={22} isMobile={isMobile} />,
    nextPhase2_3: <NextPhaseScreen setSoundIsOn={setSoundIsOn} setAudioMusic={setAudioMusic} soundIsOn={soundIsOn} handlePrev={prevStep} handleNext={nextStep} phaseKey={23} isMobile={isMobile} />,
    game3: (
      <GameScreen
        setCurrentScreen={setCurrentScreen}
        handleNext={nextStep}
        phase={PHASES[2]}
        isMobile={isMobile}
        soundIsOn={soundIsOn}
      />
    ),
    nextPhase3_1: <NextPhaseScreen setSoundIsOn={setSoundIsOn} setAudioMusic={setAudioMusic} soundIsOn={soundIsOn} handleNext={nextStep} phaseKey={31} isMobile={isMobile} />,
    nextPhase3_2: <NextPhaseScreen setSoundIsOn={setSoundIsOn} setAudioMusic={setAudioMusic} soundIsOn={soundIsOn} handleNext={nextStep} phaseKey={32} isMobile={isMobile} />,
    nextPhase3_3: <NextPhaseScreen setSoundIsOn={setSoundIsOn} setAudioMusic={setAudioMusic} soundIsOn={soundIsOn} handleNext={nextStep} phaseKey={33} isMobile={isMobile} />,

    // congrats: <Congrats handleNext={nextStep} isMobile={isMobile} />,
    share: <ShareScreen handleNext={() => setCurrentScreen("start")} isMobile={isMobile} />,
    gameOver: <GameOver setCurrentScreen={setCurrentScreen} isMobile={isMobile} indexPhase={indexPhase} />,
  };

  return (

    <div
      className={`app ${isTransitioning ? "transition" : ""}`}
      onTransitionEnd={handleTransitionEnd}
    >
      {!currentScreen.includes("game") && FLAGS.enable_glitch && (
        <GlitchBackground />
      )}
      <LineBackground />
      <BackgroundDNA />
      <AudioPlayer audioPath={audioMusic?.[0]} playInLoop soundIsOn={soundIsOn} />
      <AudioPlayer audioPath={audioMusic?.[1]} playInLoop={false} soundIsOn={soundIsOn} />
      {Screens[currentScreen]}
    </div>
  );
}

export default App;
import "./BoxItem.css";

function BoxItem({ children, className, style }) {
  return (
    <div className={"box-item " + className} style={style}>
      {" "}
      {children}
    </div>
  );
}

export default BoxItem;

import { useRef, useEffect } from "react";
import { Canvas, extend, useFrame, useThree } from "react-three-fiber";
import { ShaderMaterial, Vector2 } from "three";
import { EffectComposer, GlitchPass } from "three-stdlib";

extend({ ShaderMaterial, EffectComposer, GlitchPass });

const SketchShader = {
  uniforms: {
    time: { value: 0 },
    resolution: { value: new Vector2() },
  },
  vertexShader: `
    void main() {
      gl_Position = vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform float time;
    uniform vec2 resolution;

    void main() {
      vec2 uv = gl_FragCoord.xy / resolution.xy;

      float lines = floor(uv.y * 100.0);
      float sketch = mod(lines, 2.0);
      
      vec3 color = mix(vec3(0.04, 0.31, 0.96), vec3(0.07, 0.33, 1.0), sketch);
      
      gl_FragColor = vec4(color, 1.0);
    }
  `,
};

function Sketch() {
  const materialRef = useRef();
  const composerRef = useRef();
  const glitchPassRef = useRef();

  const { gl, clock, size } = useThree();
  const resolution = new Vector2(size.width, size.height);

  useEffect(() => {
    composerRef.current.setSize(size.width, size.height);
  }, [size.width, size.height]);

  useFrame(() => {
    const time = clock.getElapsedTime();

    materialRef.current.uniforms.time.value = time;
    glitchPassRef.current.factor = Math.abs(Math.sin(time * 0.5)) * 0.5; // Increase glitch intensity
    composerRef.current.render();
  });

  return (
    <>
      <mesh>
        <planeBufferGeometry args={[size.width, size.height]} />
        <shaderMaterial
          ref={materialRef}
          args={[SketchShader]}
          uniforms-resolution-value={resolution}
        />
      </mesh>
      <effectComposer ref={composerRef} args={[gl]}>
        <glitchPass ref={glitchPassRef} attachArray="passes" factor={0} />
      </effectComposer>
    </>
  );
}

function LineBackground() {
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Canvas style={{ opacity: 0.8 }}>
        <Sketch />
      </Canvas>
    </div>
  );
}

export default LineBackground;

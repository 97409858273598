import ButtonGame from "../ButtonGame/ButtonGame";
import { InfoContainer } from "../InfoContainer/InfoContainer";
import styles from "./styles.module.css";
import arrow from "../../assets/arrowTut.png";

export function NextPhaseScreen({ handlePrev, handleNext, phaseKey, isMobile,
  soundIsOn,
  setSoundIsOn,
  setAudioMusic
}: any) {
  const minHeight = "300px"
  const Content = {
    /////////////////////// PHASE1
    11: (
      <InfoContainer
        isMobile={isMobile}
        minHeight={minHeight}
        soundIsOn={soundIsOn}
        setSoundIsOn={setSoundIsOn}
        setAudioMusic={setAudioMusic}
      >
        <div className={styles.boxContent}>
          <h2 className={styles.boxTitle}>
            Parabéns, <br></br>você conseguiu!
          </h2>
          <p>
            Mas você está se perguntando: <br></br>
            <strong>como alguém passa a ter uma doença genética?</strong>
          </p>
          <img
            onClick={handleNext}
            src={arrow}
            className={"arrowTut"}
            alt="Next"
            draggable="false"
          />
          {/* <img
            onClick={handlePrev}
            src={arrow}
            className={"arrowTutBack"}
            alt="Next"
            draggable="false"
          /> */}
        </div>
      </InfoContainer>
    ),
    12: (
      <InfoContainer
        isMobile={isMobile}
        minHeight={minHeight}
        soundIsOn={soundIsOn}
        setSoundIsOn={setSoundIsOn}
        setAudioMusic={setAudioMusic}
      >
        <div className={styles.boxContent}>
          <p>
            As pessoas podem nascer com doenças genéticas, mas ter sintomas apenas ao longo da vida.
          </p>
          <img
            onClick={handleNext}
            src={arrow}
            className={"arrowTut"}
            alt="Next"
            draggable="false"
          />
          <img
            onClick={handlePrev}
            src={arrow}
            className={"arrowTutBack"}
            alt="Next"
            draggable="false"
          />
        </div>
      </InfoContainer>
    ),
    13: (
      <InfoContainer
        isMobile={isMobile}
        minHeight={minHeight}
        soundIsOn={soundIsOn}
        setSoundIsOn={setSoundIsOn}
        setAudioMusic={setAudioMusic}
        button={
          <ButtonGame onClick={handleNext} small={true} isMargin={true} showArrow={true} width={'60%'}>
            Fase 2
          </ButtonGame>
        }
      >
        <div className={styles.boxContent}>
          <p>
            A boa notícia é que hoje, com o avanço da ciência, para algumas doenças
            genéticas graves já existem terapias gênicas.
          </p>
          <p>Quer saber mais?</p>
          <p style={{ fontSize: '18px', marginTop: '20px' }}><b>Continue jogando!</b></p>
        </div>
      </InfoContainer>
    ),
    /////////////////////// PHASE2
    21: (
      <InfoContainer
        isMobile={isMobile}
        minHeight={minHeight}
        soundIsOn={soundIsOn}
        setSoundIsOn={setSoundIsOn}
        setAudioMusic={setAudioMusic}
      >
        <div className={styles.boxContent}>
          <h2 className={styles.boxTitle} style={{ fontSize: '22px' }}>Você mandou bem mais uma vez!</h2>
          <br></br>
          <p>
            E agora você já está pronto(a) para entender o que é a{" "}
            <strong>terapia gênica</strong>!
          </p>
          <img
            onClick={handleNext}
            src={arrow}
            className={"arrowTut"}
            alt="Next"
            draggable="false"
          />
          {/* <img
            onClick={handlePrev}
            src={arrow}
            className={"arrowTutBack"}
            alt="Next"
            draggable="false"
          /> */}
        </div>
      </InfoContainer>
    ),
    22: (
      <InfoContainer
        isMobile={isMobile}
        minHeight={minHeight}
        soundIsOn={soundIsOn}
        setSoundIsOn={setSoundIsOn}
        setAudioMusic={setAudioMusic}
      >
        <div className={styles.boxContent}>
          <p>
            A <strong>terapia gênica</strong> é um tratamento inovador que
            permite introduzir no organismo um gene que funcione
            adequadamente, mudando assim o rumo de uma doença genética!
          </p>
          <img
            onClick={handleNext}
            src={arrow}
            className={"arrowTut"}
            alt="Next"
            draggable="false"
          />
          <img
            onClick={handlePrev}
            src={arrow}
            className={"arrowTutBack"}
            alt="Next"
            draggable="false"
          />
        </div>
      </InfoContainer>
    ),
    23: (
      <InfoContainer
        isMobile={isMobile}
        minHeight={minHeight}
        soundIsOn={soundIsOn}
        setSoundIsOn={setSoundIsOn}
        setAudioMusic={setAudioMusic}
        button={
          <ButtonGame onClick={handleNext} small={true} isMargin={true} showArrow={true} width={'60%'}>
            Fase 3
          </ButtonGame>
        }
      >
        <div className={styles.boxContent}>
          <p>E foi exatamente isso que você fez aqui no jogo Hack do Bem.</p>
          <b style={{ marginTop: '15px', marginBottom: '15px', fontSize: '19px' }}>Vamos continuar?</b>
          <p>Quero ver se você é bom mesmo nesse jogo!</p>
        </div>
      </InfoContainer>
    ),
    /////////////////////// PHASE3
    31: (
      <InfoContainer
        isMobile={isMobile}
        minHeight={minHeight}
        soundIsOn={soundIsOn}
        setSoundIsOn={setSoundIsOn}
        setAudioMusic={setAudioMusic}
      >
        <div className={styles.boxContent}>
          <h2 className={styles.boxTitle}>
            Você é demais!
          </h2>
          <h3 style={{ margin: 0, fontSize: '16px' }}>
            Conseguiu cumprir a missão com muita habilidade!
          </h3>
          <p>
            Agora, você já sabe que a <b>terapia gênica</b> é uma inovação da
            medicina que trata uma alteração genética, melhorando a função do gene afetado.
          </p>
          <img
            onClick={handleNext}
            src={arrow}
            className={"arrowTut"}
            alt="Next"
            draggable="false"
          />
          {/* <img
            onClick={handlePrev}
            src={arrow}
            className={"arrowTutBack"}
            alt="Next"
            draggable="false"
          /> */}
        </div>
      </InfoContainer>
    ),
    32: (
      <InfoContainer
        isMobile={isMobile}
        minHeight={minHeight}
        soundIsOn={soundIsOn}
        setSoundIsOn={setSoundIsOn}
        setAudioMusic={setAudioMusic}
      >
        <div className={styles.boxContent}>
          <p>
            Com a terapia gênica é possível tratar uma doença genética, interrompendo ou atrasando a
            evolução dela, trazendo muita esperança para milhares de pacientes.
          </p>
          <img
            onClick={handleNext}
            src={arrow}
            className={"arrowTut"}
            alt="Next"
            draggable="false"
          />
          <img
            onClick={handlePrev}
            src={arrow}
            className={"arrowTutBack"}
            alt="Next"
            draggable="false"
          />
        </div>
      </InfoContainer>
    ),
    33: (
      <InfoContainer
        isMobile={isMobile}
        minHeight={minHeight}
        soundIsOn={soundIsOn}
        setSoundIsOn={setSoundIsOn}
        setAudioMusic={setAudioMusic}
      >
        <div className={styles.boxContent}>
          <h3 style={{ margin: 0 }}>
            Agora, que tal compartilhar seu conhecimento com os amigos e convidar todo mundo para ser um
            hacker do bem?
          </h3>
          <br></br>
          {/* <img
            onClick={handleNext}
            src={arrow}
            className={"arrowTut"}
            alt="Next"
            draggable="false"
          /> */}
          <div>
            <ButtonGame onClick={handleNext} small={true} isMargin={true} width={'100%'}>
              Compartilhar
            </ButtonGame>
          </div>
        </div>
      </InfoContainer >
    ),
  };

  return (
    <>
      {Content[phaseKey]}
    </>
  );
}

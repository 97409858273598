import boxShadowWhiteGrad from "../assets/boxShadowWhiteGrad.png";
import boxShadowRedGrad from "../assets/boxShadowRedGrad.png";
import boxYellow from "../assets/box_yellow.png";
import boxWhite from "../assets/box_white.png";
import boxBlueLeft from "../assets/box_blue_left.png";
import boxBlueRight from "../assets/box_blue_right.png";

const isLocal = location.href.includes("localhost");

const LOCAL_FLAGS = {
  enable_game_over: false,
  enable_score: true,
  start_on_game: true,
  reset_score_game: false,
  enable_audio: false,
  enable_glitch: true,
  enable_next_hack: true,
  allow_drop_rectangles_anywhere: false
};

const DEFAULT_FLAGS = {
  enable_game_over: true,
  enable_score: true,
  start_on_game: false,
  reset_score_game: false,
  enable_audio: true,
  enable_glitch: true,
  enable_next_hack: false,
  allow_drop_rectangles_anywhere: false
};

export const getScaleFactor = (ignoreScale: boolean) => {
  if (innerWidth == 1024) return 0.85

  if (innerWidth > 768 && innerWidth <= maxRes) {
    return ignoreScale ? 1 : 0.72
  }

  if (innerWidth < 768) {
    return SCALE_FACTOR;
  }
  return 1;
}
export const getScaleFactorDropX = (ignoreScale: boolean) => {
  if (innerWidth == 1024) return 0.95

  if (innerWidth > 768 && innerWidth <= maxRes) {
    return ignoreScale ? 1 : 0.9
  }

  if (innerWidth < 768) {
    return SCALE_FACTOR_DROP;
  }
  return 1;
}
export const getScaleFactorDropY = (ignoreScale: boolean) => {
  if (innerWidth == 1024) return 0.94

  if (innerWidth > 768 && innerWidth <= maxRes) {
    return ignoreScale ? 1 : 0.9
  }

  if (innerWidth < 768) {
    return SCALE_FACTOR_DROP;
  }
  return 1;
}
export const getTopScreenPos = () => {
  let top = 180;
  if (innerWidth <= 1024) top = 120

  return top;
}

export const TIME_PHASES = [60, 80, 100];
export const FLAGS = isLocal ? LOCAL_FLAGS : DEFAULT_FLAGS;
const innerWidth = window.innerWidth;
const maxRes = 1366;
const isReduced = innerWidth <= maxRes;
export const RECT_SIZE = isReduced ? 21 : 28;
export const INSIDE_RECT_SIZE = isReduced ? 15 : 22;
export const RECT_SIZE_FALL = isReduced ? 16 : 23;
export const DROPPABLE_PATTERN_NUMBER = 1;
export const YELLOW_RECT_INDEX = 4;
export const RED_RECT_INDEX = 6;
export const WHITE_RECT_INDEX = 7;
export const COL_DRAG_QUANTITY = [1, 2, 3];
// export const COL_DRAG_QUANTITY = [3];
export const PHASES = [1, 2, 3];
export const BORDER_SIZE = 1;
const colorBorderLight = "#bc0a93";
const colorBorderUp = "#00fdff90";
const colorBorderDown = "#7384db";
const colorBorderDrop = "#407cc0";
const boxShadowHackDrop = "#b1359a";
const margins = {
  margin: '3px',
}
const borderShadowInnerRectLeft = {
  borderLeft: BORDER_SIZE + "px solid " + colorBorderLight,
  borderTop: BORDER_SIZE + "px solid " + colorBorderLight,
  // borderBottom: BORDER_SIZE + "px solid " + colorBorderUp,
  // borderRight: BORDER_SIZE + "px solid " + colorBorderUp,
  boxShadow: "inset 0px 0px 0px 1px #0f23a1"
};
const borderShadowInnerRectRight = {
  borderLeft: BORDER_SIZE + "px solid " + colorBorderLight,
  borderTop: BORDER_SIZE + "px solid " + colorBorderLight,
  borderBottom: BORDER_SIZE + "px solid " + colorBorderDown,
  borderRight: BORDER_SIZE + "px solid " + colorBorderDown,
  boxShadow: "inset 0px 0px 0px 1px #0f23a1"
};

const borderShadowFilledRect = {
  borderLeft: BORDER_SIZE + "px solid " + colorBorderLight,
  borderTop: BORDER_SIZE + "px solid " + colorBorderLight,
  borderBottom: BORDER_SIZE + "px solid " + colorBorderUp,
  borderRight: BORDER_SIZE + "px solid " + colorBorderUp,
  // boxShadow: "inset 0px 0px 0px 3px #0f23a1",
};

export const COLORS = {
  WHITE: "white",
  YELLOW: "#fdcd09",
  BLUE: "#5257ab",
  LIGHT_BLUE: "#0100ca",
  CYAN: "#0195ff",
  PURPLE: "#2f0872",
  PINK: "#ef8feb",
  PINK_HACK: '#bc0a93',
  BLUE_HACK: '#0195ff',
  TRANSPARENT: 'transparent'
};

const borderDrag = {
  borderTop: `${BORDER_SIZE * 2}px solid ` + COLORS.PINK_HACK,
  borderLeft: `${BORDER_SIZE * 2}px solid ` + COLORS.PINK_HACK,
  borderBottom: `${BORDER_SIZE * 2}px solid ` + COLORS.BLUE_HACK,
  borderRight: `${BORDER_SIZE * 2}px solid ` + COLORS.BLUE_HACK,
};
export enum RECT_TYPE {
  YELLOW = "yellow",
  WHITE = "white",
  RED = "red",
  NOT_DROPPABLE = "not_droppable",
  DROPABBLE = "droppable",
  OUTSIDE_NOT_DROPPABLE = "not_droppable",
}
export const PATTERNS = {
  0: {
    identifier: "outside not droppable",
    style: {
      backgroundColor: "transparent",
    },
    draggable: false,
    droppable: false,
    rect_type: RECT_TYPE.OUTSIDE_NOT_DROPPABLE,
  },
  [DROPPABLE_PATTERN_NUMBER]: {
    identifier: "inside droppable",
    style: {
      backgroundColor: "transparent",
      borderLeft: BORDER_SIZE + "px solid " + colorBorderDrop,
      borderTop: BORDER_SIZE + "px solid " + colorBorderDrop,
      borderBottom: BORDER_SIZE + "px solid " + colorBorderDrop,
      borderRight: BORDER_SIZE + "px solid " + colorBorderDrop,
      boxShadow: "inset 0px 0px 0.3px 0.3px " + COLORS.BLUE,
      backgroundImage: `url(${boxShadowWhiteGrad})`,
      backgroundSize: 'cover'
    },
    draggable: false,
    droppable: true,
    rect_type: RECT_TYPE.DROPABBLE,
  },
  2: {
    identifier: "inside not droppable border left",
    style: {
      backgroundColor: COLORS.TRANSPARENT,
      backgroundImage: `url(${boxBlueLeft})`,
      backgroundSize: 'cover',
      ...margins,
    },
    droppable: false,
    rect_type: RECT_TYPE.NOT_DROPPABLE,
  },
  3: {
    identifier: "inside not droppable border right",
    style: {
      backgroundColor: COLORS.TRANSPARENT,
      backgroundImage: `url(${boxBlueRight})`,
      backgroundSize: 'cover',
      ...margins,
    },
    draggable: false,
    droppable: false,
    rect_type: RECT_TYPE.NOT_DROPPABLE,
  },
  [YELLOW_RECT_INDEX]: {
    identifier: "drag yellow",
    style: {
      backgroundColor: COLORS.TRANSPARENT,
      backgroundImage: `url(${boxYellow})`,
      backgroundSize: 'cover',
      ...margins,
    },
    draggable: true,
    rect_type: RECT_TYPE.YELLOW,
  },
  5: {
    identifier: "drag white",
    style: {
      backgroundColor: COLORS.TRANSPARENT,
      // ...borderShadowFilledRect,
      backgroundImage: `url(${boxWhite})`,
      backgroundSize: 'cover',
      ...margins,
    },
    draggable: false,
    rect_type: RECT_TYPE.WHITE,
  },
  [RED_RECT_INDEX]: {
    identifier: "inside droppable red",
    style: {
      backgroundColor: COLORS.PURPLE,
      borderLeft: BORDER_SIZE + "px solid " + colorBorderDrop,
      borderTop: BORDER_SIZE + "px solid " + colorBorderDrop,
      borderBottom: BORDER_SIZE + "px solid " + colorBorderDrop,
      borderRight: BORDER_SIZE + "px solid " + colorBorderDrop,
      boxShadow: "inset 0px 0px 3.5px 3.5px " + boxShadowHackDrop,
      backgroundImage: `url(${boxShadowRedGrad})`,
      backgroundSize: 'cover',
    },
    draggable: false,
    droppable: true,
    rect_type: RECT_TYPE.RED,
  },
  [WHITE_RECT_INDEX]: {
    identifier: "inside not droppable filled",
    style: {
      backgroundColor: COLORS.TRANSPARENT,
      // ...borderShadowFilledRect,
      backgroundImage: `url(${boxWhite})`,
      backgroundSize: 'cover',
      ...margins,
    },
    droppable: false,
    rect_type: RECT_TYPE.NOT_DROPPABLE,
  },
};

export const DEFAULT_NUM_OF_ROWS = 6;
export const BOTTOM_GAP_PX = -50;
export const ROWS_GAP_PX = 80;
export const TOP_OFFSET_FALL_SPEED = RECT_SIZE * 1.5;
export const DELAY_GLITCH = 2;
export const DELAY_BLINK = 80;
export const YELLOWBLOCK_THRESHOLD = 0.4;
export const OFFSET_START_ROW_Y = -100;
export const SCALE_FACTOR = 0.86;
export const SCALE_FACTOR_DROP = 0.9;
export const OFFSET_X_FALL_BLOCKS_MOB = -1;
export const DROP_COLUMNS_VALUE = -1; //2 
import { InfoContainer } from "../InfoContainer/InfoContainer";
import styles from "./styles.module.css";
import logo from "../../assets/logo.png";
import logoCircleWhite from "../../assets/new/logoCircleWhite.png";
import { downloadImage, shareNavigator } from "../../util/ShareUtils";
import { useContext, useEffect } from "react";
import { ScoreContext } from "../../contexts/ScoreContext";
import ButtonGame from "../ButtonGame/ButtonGame";

export function ShareScreen({ handleNext, isMobile }) {
  const { score, getTotalTimeGame } = useContext(ScoreContext);

  // useEffect(() => {
  //   setTimeout(() => {
  //     shareNavigator("scoreContainer")
  //   }, 500)
  // }, [])

  return (
    <InfoContainer customStyle={styles.shareInfo} isMobile={isMobile} hideLogo={true} hideSound={true}>
      <div id="sharePage">
        <div id="scoreContainer" className={styles.border}>
          <div className={styles.cardShare}>
            <div className={styles.contentShare} style={{ transform: isMobile ? 'scale(0.87)' : '' }}>
              <div className={styles.imgContainer}>
                <img src={logo} className={styles.logoCodes} draggable="false" alt="logo" />
                {/* <img src={logoCircleBlue} draggable="false" alt="logo" /> */}
                <img src={logoCircleWhite} className={styles.logoCircle} draggable="false" alt="logo" />
              </div>
              <div className={styles.messages}>
                <div className={styles.message}>
                  Missão cumprida com
                </div>
                <div className={styles.message}>
                  o <strong>#HackDoBem</strong>!
                </div>
              </div>
              <br></br>
              <div className={styles.scoreLabel}>Pontuação</div>
              <div className={styles.score}>
                <div className={styles.scoreVal}>{score}</div>
                <div className={styles.scoreLabel}>Tempo</div>
                <div className={styles.scoreVal}>{getTotalTimeGame()} </div>
              </div>
              <div className={styles.lastMessage}>
                {/* <br></br>
                <div>Desafio vocês a serem também hackers do bem</div>
                <div>Só duvido que serão melhores que eu! 😂</div> */}
                <div style={{ lineHeight: '20px' }}>
                  Acesse agora para jogar e entender como o{" "}
                  <strong>#HackdoBem</strong> explica a <b>terapia gênica</b>!
                </div>
              </div>
              <div className={styles.link}>
                <a href="www.jogohackdobem.com.br">www.jogohackdobem.com.br</a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer + " compartilharBtn"} style={{ marginTop: '10px' }}>
          <ButtonGame onClick={() => {
            // if (isMobile) {
            shareNavigator("scoreContainer")
            // } else {
            //   downloadImage("scoreContainer")
            // }
          }} small={true} isMargin={true} width={'50%'}>
            Compartilhar
          </ButtonGame>
          <ButtonGame onClick={handleNext} small={true} isMargin={true} width={'50%'}>
            Reiniciar
          </ButtonGame>
        </div>
      </div>
    </InfoContainer >
  );
}

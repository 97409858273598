import styles from "./styles.module.css";
import ButtonGame from "../ButtonGame/ButtonGame";
import logo3 from "../../assets/logo.png";
import styled from "styled-components";

export function GameOver({ setCurrentScreen, isMobile, indexPhase }) {
  console.log('indexPhase', indexPhase)
  return (
    <ContentGameOver>
      <div className={styles.logoContainer}>
        <img src={logo3} className={styles.logo} draggable="false" alt="logo" />
      </div>
      <div className={styles.content}>
        <div className={styles.whiteButton}>Game Over</div>
        <ButtonGame onClick={() => setCurrentScreen("game" + (indexPhase || 1))} small={true}>
          Novo Jogo
        </ButtonGame>
      </div>
    </ContentGameOver>
  );
}

const ContentGameOver = styled.div`
  position:absolute;
  top: 10%;
  width: 100%;
  /*transform: translateY(-50%);*/
`
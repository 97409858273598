import { useEffect, useState } from "react";
import howto1 from "../../assets/howto1.png";
import howto2 from "../../assets/howto2.png";
import arrow from "../../assets/arrowTut.png";
import styles from "./styles.module.css";
import { InfoContainer } from "../InfoContainer/InfoContainer";
import ButtonGame from "../ButtonGame/ButtonGame";

function Content1({ key }) {
  return (
    <div key={key}>
      <h3>Seja bem-vindo (a) ao Hack do Bem!</h3>
      <p>
        Nesse jogo você é um(a) <b>hacker do bem</b> e vai nos ajudar corrigir uma alteração que provoca uma
        disfunção em um trecho do DNA. Essa é a causa das doenças genéticas, você sabia?
      </p>
      <br></br>
      <p>
        E não se esqueça: <b>as sequências de DNA</b>, que formam os genes, estão presentes em todas as células e
        elas reúnem todas as <b>informações genéticas</b> de um ser vivo.
      </p>
    </div>
  );
}
function Content2({ key }) {
  return (
    <div key={key} style={{ marginTop: '20px' }}>
      <p>
        Cada informação genética está contida em um <b>trecho do DNA</b>, que
        chamamos de gene.
      </p>
      <br></br>
      <br></br>
      <p>
        Já a <b>doença genética</b> ocorre quando algum gene não funciona como foi
        concebido, ou seja, quando ocorre um <b>uma disfunção no gene</b>.
      </p>
    </div>
  );
}

function Content3({ key }) {
  return (
    <div key={key}>
      <h2 className={styles.howToHeader}>Como jogar</h2>
      <br></br>
      <img
        src={howto1}
        className={styles.howTo}
        draggable="false"
        alt="how to play"
      />
      <p style={{ marginTop: '8px' }}>Você terá que completar a estrutura do DNA preenchendo os espaços vazios com as peças que vão caindo à direita.</p>
      <p style={{ marginTop: '8px' }}>
        E será preciso hackear o <b>gene disfuncional</b> para substituí-lo por um <b>gene que funciona
          adequadamente</b>!
      </p>
    </div >
  );
}
function Content4({ key }) {
  return (
    <div key={key}>
      <h2 className={styles.howToHeader}>Como Jogar</h2>
      <br></br>
      <img
        src={howto2}
        className={styles.howTo}
        draggable="false"
        alt="how to play"
      />
      <br></br>
      <br></br>
      <p>
        Fique atento! Os trechos em <b>vermelho</b> representam os genes que precisam <b>ser corrigidos</b>.
      </p>
      <br></br>
      <p>
        Os genes que funcionam adequadamente são representados <b>pelas peças amarelas.</b>
      </p>
    </div >
  );
}
function Content5({ key, handleNextButton }) {
  return (
    <div key={key}>
      <h2 className={styles.preparado} style={{ maxWidth: '200px', margin: '10px 20px', marginTop: '40px' }}>
        Preparado(a) para ser o(a) hacker do bem?
      </h2>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <ButtonGame onClick={handleNextButton} small={true} isMargin={true} showArrow={true} width={"80%"}>
        Play
      </ButtonGame>
    </div>
  );
}

export function TutorialScreen({ handleNext, isMobile, soundIsOn, setSoundIsOn, setAudioMusic }) {
  const [currentContent, setCurrentContent] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const ContentBoxes = [
    <Content1 key={0} />,
    <Content2 key={1} />,
    <Content3 key={2} />,
    <Content4 key={3} />,
    <Content5 key={4} handleNextButton={handleNextButton} />,
  ];
  const isLastStep = currentContent === ContentBoxes.length - 1;
  const isFirstStep = currentContent === 0;

  function handleNextButton() {
    nextStepAction()
  }
  function handleNextButtonBack() {
    setCurrentContent(
      (prevContent) => (prevContent - 1) % ContentBoxes.length
    );
  }

  function handleNextButtonWithAnimation() {
    if (isTransitioning) {
      return; // Prevent advancing to the next page during the transition
    }
    setIsTransitioning(true);
  }

  function handleTransitionEnd() {
    setIsTransitioning(false);
  }

  useEffect(() => {
    if (isTransitioning) {
      const transitionTimeout = setTimeout(() => {
        nextStepAction();
      }, 300); //same delay of style
      return () => clearTimeout(transitionTimeout);
    }
  }, [isTransitioning]);

  const nextStepAction = () => {
    if (isLastStep) {
      handleNext();
    } else {
      setCurrentContent(
        (prevContent) => (prevContent + 1) % ContentBoxes.length
      );
    }
  }

  return (
    <InfoContainer isMobile={isMobile} padding={'15px 20px 0 25px'}
      minHeight={'400px'}
      soundIsOn={soundIsOn}
      setSoundIsOn={setSoundIsOn}
      setAudioMusic={setAudioMusic}
    >
      <div
        className={`${styles.boxContent} ${isTransitioning && !isLastStep ? styles.transition : ""
          }`}
      // onTransitionEnd={handleTransitionEnd}
      >
        {ContentBoxes[currentContent]}
      </div>
      <div className={styles.nextButton}>
        {!isLastStep && <>
          <button onClick={handleNextButton}>
            <img
              src={arrow}
              className={"arrowTut"}
              alt="Next"
              draggable="false"
            />
          </button>
          {!isFirstStep && <button onClick={handleNextButtonBack}>
            <img
              src={arrow}
              className={"arrowTutBack"}
              alt="Next"
              draggable="false"
            />
          </button>}
        </>}
      </div>
    </InfoContainer>
  );
}

import { createContext, useState } from "react";

type ScoreContextProps = {
  score: number;
  setScore: (score: number) => void;
  startTime: string;
  setStartTime: (score: string) => void;
  addPoints: (score: number) => void;
  countOcurrences: (matrix: number[][], value: number) => number;
  getTotalTimeGame: () => string;
};

export const INCREMENT_POINTS_PATTERN = {
  1: 10,
  2: 30,
  3: 80,
};

export const ScoreContext = createContext<ScoreContextProps>(
  {} as ScoreContextProps
);

export const ScoreProvider = ({ children }) => {
  const [score, setScore] = useState(0);
  const [startTime, setStartTime] = useState(new Date().getTime().toString());

  const countOcurrences = (matrix: number[][], value: number) => {
    let count = 0;
    for (let i = 0; i < matrix.length; i++) {
      for (let j = 0; j < matrix[i].length; j++) {
        if (matrix[i][j] === value) {
          count++;
        }
      }
    }
    return count;
  };

  const getTotalTimeGame = () => {
    const endTime = new Date().getTime();
    const difference = endTime - parseInt(startTime);
    const minutes = Math.floor(difference / 60000);
    const seconds = Math.floor((difference % 60000) / 1000);
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    const formattedDifference = `${formattedMinutes}:${formattedSeconds}`;
    return formattedDifference;
  };

  const addPoints = (type: number) => {
    const val = parseInt(document.getElementById("points").innerHTML);
    const totalPoints = val + INCREMENT_POINTS_PATTERN[type];
    document.getElementById("points").innerHTML = totalPoints;
    setScore(totalPoints);
    return totalPoints;
  };

  return (
    <ScoreContext.Provider
      value={{
        score,
        setScore,
        startTime,
        setStartTime,
        addPoints,
        countOcurrences,
        getTotalTimeGame,
      }}
    >
      {children}
    </ScoreContext.Provider>
  );
};

import { useEffect, useRef } from "react";
import { Canvas, useFrame, useThree } from "react-three-fiber";
import * as THREE from "three";
import {
  EffectComposer,
  DepthOfField,
  Glitch,
  Noise,
  Vignette,
} from "@react-three/postprocessing";

import { GlitchMode } from "postprocessing";
import { CustomGlitch } from "./CustomGlitch";
import GlitchShader from "./GlitchShader";
import { DELAY_GLITCH } from "../../util/Constants";

const GeometryBackground = () => {
  const materialRef = useRef();

  const { width, height } = useThree((state) => state.viewport);
  useEffect(() => {
    materialRef.current.uniforms.resolution.value = new THREE.Vector2(
      width,
      height
    );
  }, [width, height]);

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    materialRef.current.uniforms.time.value = time;
  });

  return (
    <mesh scale={[width, height, 1]}>
      <planeBufferGeometry args={[1, 1]} />
      <shaderMaterial ref={materialRef} args={[GlitchShader]} />
    </mesh>
  );
};

function GlitchBackground() {
  return (
    <Canvas
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
        opacity: 0.8,
      }}
    >
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      {/* <EffectComposer>
        <DepthOfField
          focusDistance={0}
          focalLength={0.02}
          bokehScale={2}
          height={480}
        />
        <CustomGlitch
          mode={GlitchMode.SPORADIC}
          active={true}
          delay={[0, 2 * DELAY_GLITCH]}
          duration={[0.6, 1.0]}
          strength={[0.3, 0.6]}
          chromaticAberrationOffset={[0, 0]}
        />
        <Noise opacity={0.02} />
        <Vignette eskil={false} offset={0.1} darkness={1.1} />
      </EffectComposer> */}
      <GeometryBackground />
    </Canvas>
  );
}

export default GlitchBackground;
